import styled from "styled-components";

import { Thumbnail as ThumbnailBase, InlineBox as InlineBoxBase } from "../../../../UIElements";
import { Media } from "../../../../theme";
import { IconWrapper as IconWrapperBase } from "../certificatePath/certificatePath.styled";

export const InlineBox = styled(InlineBoxBase)`
  padding: 17px 0;
  align-items: flex-start;
  flex-direction: column;

  ${Media.tablet`
    align-items: center;
    flex-direction: row;
  `}
`;

export const Thumbnail = styled(ThumbnailBase)`
  width: calc(100% - 40px);

  ${Media.tablet`
    width: 110px;
  `}
`;

export const ThumbnailWithText = styled.div`
  ${Media.tablet`
    display: flex;
    flex-shrink: 0;
  `}

  ${Media.tabletWide`
    width: 110px;
  `}
`;

export const IconWrapper = styled(IconWrapperBase)`
  margin-top: 7px;

  ${Media.tablet`
    margin-top: 0;
  `}
`;
