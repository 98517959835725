export const ExternalRegistration = {
  Disabled: "disabled",
  Enabled: "enabled",
};

export const SectionName = {
  Account: "account",
  User: "user",
  Company: "company",
  Agreements: "agreements",
};
